import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import Image from 'next/image';
import { StaticImageData } from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Benefit1Img from '@images/homepage/benefit1.png';
import Benefit2Img from '@images/homepage/benefit2.png';
import Benefit3Img from '@images/homepage/benefit3.png';

export default function WhyHeycater() {
  const { t } = useTranslation('homepage');

  return (
    <Container>
      <Wrapper>
        <WhyHeycaterLeftBox>
          <Title>{t('whyHeycater.title')}</Title>
          <Box my={3}>
            <Paragraph>{t('whyHeycater.subtitle')}</Paragraph>
          </Box>
          <ButtonContainer>
            <a href="#qf-start">
              <StyledButton variant="primary">
                {t('whyHeycater.cta')}
              </StyledButton>
            </a>
          </ButtonContainer>
        </WhyHeycaterLeftBox>
        <WhyHeycaterRightBox>
          <BenefitCard
            image={Benefit1Img}
            title={t('weAreHeycater.card1.title')}
            subtitle={t('weAreHeycater.card1.subtitle')}
          />
          <BenefitCard
            image={Benefit2Img}
            title={t('weAreHeycater.card2.title')}
            subtitle={t('weAreHeycater.card2.subtitle')}
          />
          <BenefitCard
            image={Benefit3Img}
            title={t('weAreHeycater.card3.title')}
            subtitle={t('weAreHeycater.card3.subtitle')}
          />
        </WhyHeycaterRightBox>
      </Wrapper>
    </Container>
  );
}

interface BenefitCardProps {
  image: StaticImageData;
  title: string;
  subtitle: string;
}

function BenefitCard({ image, title, subtitle }: BenefitCardProps) {
  return (
    <Card>
      <ImageBox>
        <Image priority src={image} width={75} height={65} quality={100} />
      </ImageBox>
      <Box>
        <Head mb={2}>{title}</Head>
        <Subtitle>{subtitle}</Subtitle>
      </Box>
    </Card>
  );
}

const WhyHeycaterLeftBox = styled.div`
  flex: 1 1 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: start;
  }
`;

const Head = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.88px;
`;

const ImageBox = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: fill-available;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: auto;
  }
`;

const Subtitle = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 23.52px;
`;

const Card = styled.div`
  display: flex;
  align-items: start;
  gap: ${({ theme }) => theme.spacing(3)}px;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

const WhyHeycaterRightBox = styled.div`
  max-width: 510px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1 1 0px;
`;

const StyledButton = styled(Button)`
  border-radius: 47px;
  padding: ${({ theme }) => theme.spacing(1.5, 3)};
`;

const Title = styled(Typography)`
  font-size: 36px;
  font-weight: 700;
  line-height: 62.1px;
`;

const Paragraph = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 26.88px;
`;

const Wrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  flex-direction: column;
  gap: 48px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    width: 1108px;
    padding: 40px 0;
  }
`;

const Container = styled.div``;
